.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background: #ffff;
    /* Blue color */
    opacity: 1;
    
}
/* styles.css */

/* Center the pagination bullets and move them below the slides */
.swiper-pagination {
    position: absolute;
    bottom: 10px;
    /* Adjust the distance from the bottom as needed */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 100%;
}

/* Style for the pagination bullets */
.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background: #ffffff;
    /* Blue color */
    opacity: 1;
    border-radius: 50%;
    margin: 0 4px;
    /* Space between bullets */
    transition: background 0.3s ease;
}

/* Style for the active pagination bullet */
.swiper-pagination-bullet-active {
    background: #eee;
    /* Orange color for active bullet */
}