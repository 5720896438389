input {
  height: 0.9rem;
  width: 0.9rem;
}

input[type="checkbox"]:checked {
  border: none;
  box-shadow: 0 0 0 2px lightblue;
}

.product-link {
  width: 46%;
}

@media only screen and (max-width: 22em) {
  .product-link {
    width: 100%;
  }
}

@media only screen and (min-width: 37.5em) {
  .product-link {
    width: 30.3333333333%;
  }
}

@media only screen and (min-width: 46.875em) {
  .product-link {
    width: 30.3333333333%;
  }
}

@media only screen and (min-width: 59.375em) {
  .product-link {
    width: 30.3333333333%;
  }
}

@media only screen and (min-width: 78.125em) {
  .product-link {
    width: 30.3333333333%;
  }
}



.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
  color: #000000;
  /* Changes the arrow color */
  /* Optional: changes the background color */
}

.mySwiper .swiper-button-next:hover,
.mySwiper .swiper-button-prev:hover {
  
  /* Color on hover */
 
  /* Background color on hover */
}